.container__menu-team {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container__menu-team__output {
    
    width: 35vh;
    height: 12vh;
    background-color: rgb(73, 0, 102);
    
    margin: 3vh;
    border-radius: 1.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7vh;
    color: aliceblue;
}
.container__menu-team__btn button{
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26vh;
    height: 10vh;
    margin: 3vh;
    border: 0;
    border-radius: 1.2vh;
    font-size: 3vh;
    background-color: rgb(68, 0, 131);
    color: aliceblue;
     
}
.container__menu-team__btn button:active {
    transform: scale(0.95);
    transition: .25s;
}
.start-game__btn{
        
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26vh;
    height: 10vh;
    margin: 2vh;
    border: 0;
    border-radius: 1.2vh;
    font-size: 3vh;
    background-color: rgb(41, 7, 7);
    color: aliceblue;
}
.is-active__btn{
    box-shadow: 0px 0px 1.7vh 0.1vh rgba(61, 255, 2, 0.7);
    
}
.time-team{
    animation: pulseTimeTeam 1s infinite;
}

.little-time{
    animation: pulseLittleTime 0.5s infinite;
}

@keyframes pulseLittleTime {
    0% {
        box-shadow: 0px 0px 0.5vh 0vh rgba(241, 52, 5, 0.856);
    }
    50% {
        box-shadow: 0px 0px 1.7vh 0.1vh rgba(241, 52, 5, 0.856);
    }
    100% {
        box-shadow: 0px 0px 0.5vh 0vh rgba(241, 52, 5, 0.856);
    }
  }
@keyframes pulseTimeTeam {
    0% {
        box-shadow: 0px 0px 0.5vh 0vh rgba(255, 153, 0, 0.856);
    }
    50% {
        box-shadow: 0px 0px 1.7vh 0.1vh rgba(255, 153, 0, 0.856);
    }
    100% {
        box-shadow: 0px 0px 0.5vh 0vh rgba(255, 153, 0, 0.856);
    }
  }